import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import mdToHtml from '../util/mdToHtml';

import Layout from '../components/Layout';
import Masthead from '../components/Masthead';
import CtaBar from '../components/CtaBar';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';
import RelatedInsights from '../components/RelatedInsights';
import { PageWrap } from '../styles/common';

export const InsightDetailTemplate = ({
	title,
	content,
	publishedDate,
	thumbnailImage,
	mastheadImage,
	industries,
}) => {
	return (
		<PageWrap>
			<MastheadWrap>
				<Masthead
					image={mastheadImage}
					title={title}
					sub={publishedDate}
					blog={true}
				/>
			</MastheadWrap>
			<div className="container" style={{ padding: '0 2rem' }}>
				<div className="columns">
					<div className="column is-8">
						<Content>
							<div
								style={{ padding: '2rem 0' }}
								dangerouslySetInnerHTML={{
									__html: mdToHtml(content),
								}}
							/>
						</Content>
					</div>
				</div>
			</div>
			<RelatedWrap>
				<div style={{ textAlign: 'center' }}>
					<h3>You might also be interested in:</h3>
				</div>
				<RelatedInsights />
			</RelatedWrap>
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const InsightDetail = ({ data }) => {
	const {
		title,
		content,
		published_date,
		thumbnail_image,
		masthead_image,
		industries,
	} = data.insightsJson;

	return (
		<Layout>
			<Helmet>
				<title>{title} | Spergel Corporate</title>
				<style>{`div#chat-widget-container {display: none;}`}</style>
			</Helmet>
			<InsightDetailTemplate
				title={title}
				content={content}
				publishedDate={published_date}
				thumbnailImage={thumbnail_image}
				mastheadImage={masthead_image}
				industries={industries}
			/>
		</Layout>
	);
};

export default InsightDetail;

export const insightDetailQuery = graphql`
	query Insight($id: String!) {
		insightsJson(id: { eq: $id }) {
			title
			content
			published_date(formatString: "MMMM Do, YYYY")
			thumbnail_image
			masthead_image
			industries {
				industry
			}
			fields {
				slug
			}
		}
	}
`;

const RelatedWrap = styled.div`
	background-color: #f2f2f2;
	padding: 4rem 0 6rem 0;

	h2 {
		font-weight: 700;
	}
	h3 {
		font-weight: 400;
		font-size: 1.75rem;
		margin-bottom: 4rem;
	}
	a {
		margin-left: 0;
		color: inherit;
	}
`;

const Content = styled.div`
	img {
		width: 100%;
	}
	a {
		color: inherit;
	}
	li {
		font-size: 1rem;
		font-family: 'Merriweather', serif;
		line-height: 1.5;
	}
`;

const MastheadWrap = styled.div`
	& > div {
		padding: 0 2rem;
	}
	h2 {
		font-size: 1.5rem;
		@media only screen and (min-width: 1088px) {
			font-size: 3rem;
		}
	}
	h3 {
		font-size: 1rem;
		@media only screen and (min-width: 1088px) {
			font-size: 1.5rem;
		}
	}
`;
